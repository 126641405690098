document.addEventListener("DOMContentLoaded", function(event) {
    isMobile = false;
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
        isMobile = true;
    }

    function checkMobile() {
        if (isMobile) {
            document.querySelector("body").classList.add("is--mobile");
            document.querySelector("body").classList.remove("is--desktop");
        } else {
            document.querySelector("body").classList.remove("is--mobile");
            document.querySelector("body").classList.add("is--desktop");
        }
    }
    checkMobile();

    function isInViewport(element) {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 && rect.left >= 0 &&
            // rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    let swiperScript = 0;

    window.addEventListener("scroll", function(e) {

        if (document.querySelectorAll(".header").length) {
            var navbar = document.querySelectorAll(".header")[0];

            function addFixedMenu() {
                if (window.pageYOffset >= navbar.clientHeight + 300) {
                    navbar.classList.add("fixed-header-scroll");
                    document.querySelector("body").classList.add("menu-is-fixed");
                } else {
                    navbar.classList.remove("fixed-header-scroll");
                    document.querySelector("body").classList.remove("menu-is-fixed");
                }
            }

            if (document.body.clientHeight > (window.innerHeight + 400)) {
                addFixedMenu();
            }
        }
    });



    let slideUp = (target, duration = 500) => {

        target.style.transitionProperty = 'height, margin, padding';
        target.style.transitionDuration = duration + 'ms';
        target.style.boxSizing = 'border-box';
        target.style.height = target.offsetHeight + 'px';
        target.offsetHeight;
        target.style.overflow = 'hidden';
        target.style.height = 0;
        target.style.paddingTop = 0;
        target.style.paddingBottom = 0;
        target.style.marginTop = 0;
        target.style.marginBottom = 0;
        window.setTimeout(() => {
            target.style.display = 'none';
            target.style.removeProperty('height');
            target.style.removeProperty('padding-top');
            target.style.removeProperty('padding-bottom');
            target.style.removeProperty('margin-top');
            target.style.removeProperty('margin-bottom');
            target.style.removeProperty('overflow');
            target.style.removeProperty('transition-duration');
            target.style.removeProperty('transition-property');
            //alert("!");
        }, duration);
    }

    /* SLIDE DOWN */
    let slideDown = (target, duration = 500) => {

        target.style.removeProperty('display');
        let display = window.getComputedStyle(target).display;
        if (display === 'none') display = 'block';
        target.style.display = display;
        let height = target.offsetHeight;
        target.style.overflow = 'hidden';
        target.style.height = 0;
        target.style.paddingTop = 0;
        target.style.paddingBottom = 0;
        target.style.marginTop = 0;
        target.style.marginBottom = 0;
        target.offsetHeight;
        target.style.boxSizing = 'border-box';
        target.style.transitionProperty = "height, margin, padding";
        target.style.transitionDuration = duration + 'ms';
        target.style.height = height + 'px';
        target.style.removeProperty('padding-top');
        target.style.removeProperty('padding-bottom');
        target.style.removeProperty('margin-top');
        target.style.removeProperty('margin-bottom');
        window.setTimeout(() => {
            target.style.removeProperty('height');
            target.style.removeProperty('overflow');
            target.style.removeProperty('transition-duration');
            target.style.removeProperty('transition-property');
        }, duration);
    }

    /* TOOGLE */
    var slideToggle = (target, duration = 500) => {
        if (window.getComputedStyle(target).display === 'none') {
            return slideDown(target, duration);
        } else {
            return slideUp(target, duration);
        }
    }


    document.querySelector(".nav-toggle").addEventListener("click", function(e) {
        e.preventDefault();
        e.stopPropagation();
        this.classList.toggle("open");
        if (this.classList.contains("open")) {
            document.querySelector("body").classList.add("menu-open");
            slideDown(document.querySelector(".nav"), 300);
        } else {
            if (document.querySelector(".nav ul.open")) {
                slideUp(document.querySelector(".nav ul.open"), 300);
            }
            slideUp(document.querySelector(".nav"), 300);
            document.querySelector("body").classList.remove("menu-open");
        }
        e.preventDefault();
        e.stopPropagation();
    })

    const navItemToOpenA = document.querySelectorAll('.menu__item--has-children>a');
    const navItemToOpenLi = document.querySelectorAll('.menu__item--has-children');


    // previousElementSibling
    if (window.ontouchstart !== undefined) {
        document.querySelector("body").classList.add("menu-mobile");

        for (var i = 0; i < navItemToOpenA.length; i++) {
            navItemToOpenA[i].addEventListener("click", function(e) {
                e.preventDefault();
                e.stopPropagation();
                let submenu = this.nextElementSibling;

                if (submenu.classList.contains("open")) {
                    slideUp(submenu, 300);
                    submenu.classList.remove("open");
                } else {
                    if (document.querySelector(".nav ul.open")) {
                        slideUp(document.querySelector(".nav ul.open"), 300);
                        document.querySelector(".nav ul.open").classList.remove("open");
                    }
                    slideDown(submenu, 300);
                    submenu.classList.add("open");
                }
            });
        }


        // $(".team__item").click(function(e) {
        //     if ($(this).hasClass("open")) {
        //         $(this).removeClass("open");
        //     } else {
        //         $(".team__item.open").removeClass("open");
        //         $(this).addClass("open");
        //     }
        // })

    } else {
        for (var i = 0; i < navItemToOpenA.length; i++) {
            navItemToOpenA[i].addEventListener("click", function(e) {
                e.preventDefault();
                e.stopPropagation();
            });
        }
        for (var i = 0; i < navItemToOpenLi.length; i++) {
            navItemToOpenLi[i].addEventListener("mouseenter", function(e) {               
                this.querySelector('ul').style.display = "block";
            });
        }
        for (var i = 0; i < navItemToOpenLi.length; i++) {
            navItemToOpenLi[i].addEventListener("mouseleave", function(e) {
            
                this.querySelector('ul').style.display = "none";
            });
        }
        // for (var i = 0; i < teamItemList.length; i++) {
        //     teamItemList[i].addEventListener("mouseenter", function(e) {
        //         this.classList.add("open");
        //     });
        // }
        // for (var i = 0; i < teamItemList.length; i++) {
        //     teamItemList[i].addEventListener("mouseout", function(e) {
        //         this.classList.remove("open");
        //     });
        // }       
    }

    document.addEventListener('click', function(e) {

        if (!e.target.parentNode.classList.contains("team__item-right") && !e.target.classList.contains("team__item-right") && !e.target.parentNode.classList.contains("team__noclick")) {
            if (document.querySelector(".team__item.open")) {
                document.querySelector(".team__item.open").classList.remove("open");
            }

        }
    });
    const teamItemList = document.querySelectorAll('.team__item-left');
    const teamClose = document.querySelectorAll('.team__close');
    for (let i = 0; i < teamItemList.length; i++) {
        teamItemList[i].addEventListener("click", function(e) {
           
            let teamItem = this.parentNode;
            if (teamItem.classList.contains("open")) {
                teamItem.classList.remove("open")
            } else {
                if (document.querySelector(".team__item.open")) {
                    document.querySelector(".team__item.open").classList.remove("open")
                }
                teamItem.classList.add("open")
            }
        });
    }

    function accordion(accordion) {
        let accordionContent = accordion.querySelector(".accordion__content");
        let accordionBtn = accordion.querySelector("button");
        let height = accordionContent.offsetHeight;

        accordionContent.setAttribute("data-height", height + "px");
        accordionContent.classList.contains("active") ? accordionContent.style.height = height + "px" : accordionContent.style.height = 0;
        accordionBtn.addEventListener("click", function() {
            if (accordion.classList.contains("active")) {
                this.classList.remove("active");
                this.setAttribute('aria-expanded', false);
                accordionContent.classList.remove("active");
                accordionContent.setAttribute('aria-hidden', true);
                accordionContent.style.height = 0;
                accordion.classList.remove("active");
            } else {
                this.classList.add("active");
                this.setAttribute('aria-expanded', true);
                accordion.classList.add("active");
                accordionContent.setAttribute('aria-hidden', false);
                accordionContent.classList.add("active");
                accordionContent.style.height = accordion.querySelector(".accordion__content").getAttribute("data-height");
            }
        })
    }

    Array.prototype.map.call(document.querySelectorAll(".accordion__panel"), function(item) { accordion(item) });


    if (document.querySelector(".offert__wrapper")) {
        document.querySelector(".offert__wrapper").style.minHeight = document.querySelector(".offert__wrapper").clientHeight + "px";
        const offerBtn = document.querySelectorAll(".offert__nav button");
        const offerItem = document.querySelectorAll(".offert__item")
        for (let i = 0; i < offerBtn.length; i++) {
            offerBtn[i].addEventListener("click", function(e) {
                e.preventDefault();

                let offerKind = this.getAttribute("data-name");
                if (this.classList.contains("active")) {
                    if (document.querySelector(".offert__nav button.active")) { document.querySelector(".offert__nav button.active").classList.remove("active"); }
                    for (let i = 0; i < offerItem.length; i++) {
                        offerItem[i].classList.remove("offer-hide");
                        offerItem[i].classList.add("offer-show");
                    }
                    // setTimeout(function() {
                    //     for (let i = 0; i < offerItem.length; i++) {
                    //         offerItem[i].classList.remove("offer-show");
                    //     }
                    // }, 300)
                } else {
                    if (document.querySelector(".offert__nav button.active")) { document.querySelector(".offert__nav button.active").classList.remove("active"); }
                    this.classList.add("active");

                    for (let i = 0; i < offerItem.length; i++) {


                        if (offerItem[i].getAttribute("data-name") == offerKind) {
                            // offerItem[i].classList.add("offer-show");
                            offerItem[i].classList.remove("offer-hide");
                        } else {
                            offerItem[i].classList.add("offer-hide");
                            // offerItem[i].classList.remove("offer-show");
                        }

                    }
                }

            });
        }
    }



    //GESTURE




    //swiper slder

    //TAB
    var tabs = document.querySelectorAll('[role=tab]'); //get all role=tab elements as a variable
    for (i = 0; i < tabs.length; i++) {
        tabs[i].addEventListener("click", showTabPanel);
    } //add click event to each tab to run the showTabPanel function 
    function showTabPanel(el) { //runs when tab is clicked
        var tabs2 = document.querySelectorAll('[role=tab]'); //get tabs again as a different variable 
        for (i = 0; i < tabs2.length; i++) {
            tabs2[i].setAttribute('aria-selected', 'false');

        } //reset all tabs to aria-selected=false and normal font weight
        el.target.setAttribute('aria-selected', 'true'); //set aria-selected=true for clicked tab       
        var tabPanelToOpen = el.target.getAttribute('aria-controls'); //get the aria-controls value of the tab that was clicked
        var tabPanels = document.querySelectorAll('[role=tabpanel]'); //get all tabpanels as a variable
        for (i = 0; i < tabPanels.length; i++) {
            tabPanels[i].setAttribute("aria-hidden", "true");
            tabPanels[i].classList.remove("is-current");

        } //hide all tabpanels
        document.getElementById(tabPanelToOpen).setAttribute("aria-hidden", "false");
        document.getElementById(tabPanelToOpen).classList.add("is-current"); //show tabpanel who's tab was clicked
    }
    // $('[role=tablist]').keydown(function(e) {
    //     if (e.keyCode == 37) {
    //         $("[aria-selected=true]").prev().click().focus();
    //         e.preventDefault();
    //     }
    //     if (e.keyCode == 38) {
    //         $("[aria-selected=true]").prev().click().focus();
    //         e.preventDefault();
    //     }
    //     if (e.keyCode == 39) {
    //         $("[aria-selected=true]").next().click().focus();
    //         e.preventDefault();
    //     }
    //     if (e.keyCode == 40) {
    //         $("[aria-selected=true]").next().click().focus();
    //         e.preventDefault();
    //     }
    // });
    let touchstartX = 0;
    let touchendX = 0;


    if (document.querySelector('.treatments__right')) {
        const gestureZone = document.querySelector('.treatments__right');


        gestureZone.addEventListener('touchstart', function(event) {
            touchstartX = event.changedTouches[0].screenX;
        }, false);

        gestureZone.addEventListener('touchend', function(event) {
            touchendX = event.changedTouches[0].screenX;
            handleGesture();
        }, false);
    }

    function handleGesture() {
        if (touchendX >= touchstartX) {
            if (document.querySelector(".treatments [aria-selected=true]").parentNode.previousElementSibling) {
                document.querySelector(".treatments [aria-selected=true]").parentNode.previousElementSibling.querySelector("button").click().focus();
            }
        }
        if (touchendX <= touchstartX) {
            if (document.querySelector(".treatments [aria-selected=true]").parentNode.nextElementSibling) { document.querySelector(".treatments [aria-selected=true]").parentNode.nextElementSibling.querySelector("button").click().focus(); }
        }

    };






    let observeOptions = {
        root: null,
        margin: '0px',
        threshold: 0.4,
    }
    let observeOptionsFast = {
        root: null,
        margin: '0px',
        threshold: 0.1,
    }

    let onObserve = function(entries, observer) {
        let el = entries[0].target;

        let elChildren = el.querySelectorAll(".offert__item");

        for (i = 0; i < entries.length; ++i) {

            if (entries[i].isIntersecting) {
                if (!el.classList.contains("io-loaded")) {
                    for (i = 0; i < elChildren.length; ++i) {
                        elChildren[i].classList.add('offer-show');
                    }
                    el.classList.add('io-loaded');
                    setTimeout(function() {
                        el.classList.add('io-loaded-all');
                    }, 800);

                }
            }
        }

    }


    if (document.querySelector('.offert__wrapper')) {
        const iObserver = new IntersectionObserver(onObserve, observeOptions);
        if (document.querySelector(".offert__wrapper")) { iObserver.observe(document.querySelector(".offert__wrapper")); }
    }



    let onObserve2 = function(entries, observer) {
        for (i = 0; i < entries.length; ++i) {
            let el = entries[i].target;

            if (entries[i].isIntersecting) {
           
                if (!el.classList.contains("wow-loaded")) {
                    el.classList.add('wow-loaded');
                }
            }
        }
    }

    if (document.querySelector('.wow-team')) {
        const iObserver3 = new IntersectionObserver(onObserve2, observeOptionsFast);
        iObserver3.observe(document.querySelector(".wow-team"));
    }

    if (document.querySelector('.wow')) {
        let wowItem = document.querySelectorAll(".wow");

        for (i = 0; i < wowItem.length; ++i) {
            const iObserver2 = new IntersectionObserver(onObserve2, observeOptions);
            iObserver2.observe(wowItem[i]);
        }
    }

    if (document.querySelector('.link__parent')) {
        let linkParents = document.querySelectorAll(".link__parent");
 
        for (i = 0; i < linkParents.length; ++i) {
            linkParents[i].addEventListener("click", function() {
                let href = linkParents[i].querySelector("a").getAttribute("href");
               
                if(href)
               { window.location.href = href;}
            });
        }
    }


});